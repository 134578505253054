<template>
	<!-- 检验-报验信息 -->
	<div class="mod-config">
		<el-card body-style="padding-bottom: 0;" style="margin-top: -5px;">
			<div class="title">船体结构进度</div>
			<div class="line"></div>
			<el-row style="margin-top: -8px;margin-left: -20px;line-height:30px;width: 103%;">
				<el-row style="margin-top: 10px;">
					<el-col :span="2">
						<div class="search_title">标的物</div>
					</el-col>
					<el-col :span="22" style="display: flex;flex-wrap: wrap;">
						<span v-for="(subject, index) in subjectList" style="margin-right: 20px;">
							<span class="search_bn_border" v-if="subjectNo === subject.displayValue"
								@click="setSubject(subject)">
								{{ subject.displayName }}
							</span>
							<span class="search_bn" v-else-if="subjectNo !== subject.displayValue"
								@click="setSubject(subject)">
								{{ subject.displayName }}
							</span>
						</span>
					</el-col>
				</el-row>
				<div class="line" style="margin-top: 10px;"></div>
				<el-row style="background-color: rgb(243, 247, 247);margin-top: -10px;height: 45px;width: 103%;">
					<el-col :span="pageSpan" v-if="showAdd" style="margin-left:15px;margin-top:5px">
						<el-button v-preventReClick class="btn" type="primary" size="small" :disabled="updateFlg || addFlg"
							@click="addHandle()">添加</el-button>
						<el-button v-preventReClick :disabled="disabledBatchDelete || updateFlg || addFlg" class="btn"
							type="primary" size="small" @click="deleteBatch()">删除</el-button>
						<el-button v-preventReClick class="btn" type="primary" size="small" :disabled="updateFlg || addFlg"
							@click="uploadHandle()">导入</el-button>
						<el-button v-preventReClick class="btn" type="primary" size="small" :disabled="updateFlg || addFlg"
							@click="downloadHandle()">导出</el-button>
						<el-button v-preventReClick class="btn" type="primary" size="small" :disabled="updateFlg || addFlg"
							@click="downloadTemplate()">模板下载</el-button>
					</el-col>
				</el-row>
				<div class="line" style="margin-top: -3px;"></div>
				<el-form ref="form" :model="form" inline label-width="80px" label-position="left">
					<el-row style="background-color: rgb(243, 247, 247);margin-top: -10px;height: 45px;width: 103%;">
						<el-col :span="18" style="margin-left:20px">
							<!-- <el-form-item label="标的物" class="selFormItem">
								<el-select class="selSubjectInput" v-model="subjectNo" placeholder="请选择"
									:disabled="updateFlg || addFlg">
									<el-option v-for="item in $store.state.common.navBarSubjectList"
										:key="item.displayValue" :label="item.displayName" :value="item.displayValue">
									</el-option>
								</el-select>
							</el-form-item> -->
							<el-form-item label="分段号" class="selFormItem">
								<el-input class="selSearchInput" type="input" v-model="partNo" placeholder="请输入"
									:disabled="updateFlg || addFlg" clearable></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="4" class="selBtnGroup" style="margin-top:5px">
							<el-button v-preventReClick class="btn" type="primary" size="small"
								:disabled="updateFlg || addFlg" @click="selGetDataList()" v-if="showSearch">查询</el-button>
							<el-button v-preventReClick class="rebtn" size="small" :disabled="updateFlg || addFlg"
								@click="resetFormData()">重置</el-button>
						</el-col>
					</el-row>
				</el-form>
			</el-row>
		</el-card>
		<div>
			<el-card style="margin-top: -25px; height: 500px;" body-style="height:460px; padding: 0;">
				<el-row style="height: 100%; margin-bottom: 10px;">
					<el-col :span="24" style="height: 100%;">
						<div class="box">
							<el-table height="440" :row-class-name="tableRowClassName" :row-style="selectRowBackground"
								ref="dataListTable" class="dataListTable" :data="dataList" header-align="center" border
								style="width: 100%;margin-bottom: 20px;" header-cell-class-name="dataListTHeader"
								@selection-change="handleSelectionChange" :cell-style="{ padding: '0px' }">
								<el-table-column type="selection" width="45" align="center"></el-table-column>
								<el-table-column type="index" label="NO." width="55" align="center">
									<template slot-scope="scope">{{ (pageIndex2 - 1) * pageSize + scope.$index + 1
									}}</template>
								</el-table-column>
								<!-- <el-table-column prop="subjectName" label="标的物" width="120" align="left">
									<template slot-scope="scope">
										<span style="margin-left: 15px;" v-if="!getDisabled(scope.row.recNo)">{{
											scope.row.subjectName
										}}</span>
										<el-input class="sp-input" v-if="getDisabled(scope.row.recNo) && !addFlg"
											:disabled="true" v-model="scope.row.subjectName"></el-input>
										<el-select class="sp-input" v-model="scope.row.subjectNo" placeholder="请选择"
											v-if="getDisabled(scope.row.recNo) && addFlg" style="width:110px">
											<el-option v-for="item in subjectList"
												:key="item.displayValue" :label="item.displayName"
												:value="item.displayValue">
											</el-option>
										</el-select>
									</template>
								</el-table-column> -->
								<el-table-column prop="partNo" label="分段号" align="left" min-width="200px">
									<template slot-scope="scope">
										<span style="margin-left: 15px;" v-if="!getDisabled(scope.row.recNo)">{{
											scope.row.partNo
										}}</span>
										<el-input class="sp-input" v-if="getDisabled(scope.row.recNo)"
											v-model="scope.row.partNo"></el-input>
									</template>
								</el-table-column>
								<el-table-column prop="partUpDate" label="上胎时间" width="200" align="center">
									<template slot-scope="scope">
										<span
											v-if="scope.row.unusePartUpDate == '1' && !getDisabled(scope.row.recNo)">/</span>
										<span v-if="scope.row.unusePartUpDate == '0' && !getDisabled(scope.row.recNo)">{{
											scope.row.partUpDate }}</span>
										<el-date-picker v-model="scope.row.partUpDate" type="date" value-format="yyyy-MM-dd"
											placeholder="选择日期" :disabled="scope.row.unusePartUpDate == '1'"
											v-if="getDisabled(scope.row.recNo)" style="width: 135px;">
										</el-date-picker>
										<el-switch v-model="scope.row.unusePartUpDate" v-if="getDisabled(scope.row.recNo)"
											active-value="1" inactive-value="0" style="margin-left: -7px;"
											active-color="#cdcdce" inactive-color="#7dbae2"></el-switch>
									</template>
								</el-table-column>
								<el-table-column prop="partCheckFinishDate" label="验收完成时间" width="200" align="center">
									<template slot-scope="scope">
										<span
											v-if="scope.row.unusePartCheckFinishDate == '1' && !getDisabled(scope.row.recNo)">/</span>
										<span
											v-if="scope.row.unusePartCheckFinishDate == '0' && !getDisabled(scope.row.recNo)">{{
												scope.row.partCheckFinishDate }}</span>
										<el-date-picker v-model="scope.row.partCheckFinishDate" type="date"
											value-format="yyyy-MM-dd" placeholder="选择日期"
											:disabled="scope.row.unusePartCheckFinishDate == '1'"
											v-if="getDisabled(scope.row.recNo)" style="width: 135px;">
										</el-date-picker>
										<el-switch v-model="scope.row.unusePartCheckFinishDate"
											v-if="getDisabled(scope.row.recNo)" active-value="1" inactive-value="0"
											style="margin-left: -7px;" active-color="#cdcdce"
											inactive-color="#7dbae2"></el-switch>
									</template>
								</el-table-column>
								<el-table-column prop="partPaintingFinishDate" label="涂装完成时间" width="200" align="center">
									<template slot-scope="scope">
										<span
											v-if="scope.row.unusePartPaintingFinishDate == '1' && !getDisabled(scope.row.recNo)">/</span>
										<span
											v-if="scope.row.unusePartPaintingFinishDate == '0' && !getDisabled(scope.row.recNo)">{{
												scope.row.partPaintingFinishDate }}</span>
										<el-date-picker v-model="scope.row.partPaintingFinishDate" type="date"
											value-format="yyyy-MM-dd" placeholder="选择日期"
											:disabled="scope.row.unusePartPaintingFinishDate == '1'"
											v-if="getDisabled(scope.row.recNo)" style="width: 135px;">
										</el-date-picker>
										<el-switch v-model="scope.row.unusePartPaintingFinishDate"
											v-if="getDisabled(scope.row.recNo)" active-value="1" inactive-value="0"
											style="margin-left: -7px;" active-color="#cdcdce"
											inactive-color="#7dbae2"></el-switch>
									</template>
								</el-table-column>
								<el-table-column prop="totalPartFinishDate" label="总段验收完成" width="200" align="center">
									<template slot-scope="scope">
										<span
											v-if="scope.row.unuseTotalPartFinishDate == '1' && !getDisabled(scope.row.recNo)">/</span>
										<span
											v-if="scope.row.unuseTotalPartFinishDate == '0' && !getDisabled(scope.row.recNo)">{{
												scope.row.totalPartFinishDate }}</span>
										<el-date-picker v-model="scope.row.totalPartFinishDate" type="date"
											value-format="yyyy-MM-dd" placeholder="选择日期"
											:disabled="scope.row.unuseTotalPartFinishDate == '1'"
											v-if="getDisabled(scope.row.recNo)" style="width: 135px;">
										</el-date-picker>
										<el-switch v-model="scope.row.unuseTotalPartFinishDate"
											v-if="getDisabled(scope.row.recNo)" active-value="1" inactive-value="0"
											style="margin-left: -7px;" active-color="#cdcdce"
											inactive-color="#7dbae2"></el-switch>
									</template>
								</el-table-column>
								<el-table-column prop="partUpLastDate" label="上船台(总组)时间" width="200" align="center">
									<template slot-scope="scope">
										<span
											v-if="scope.row.unusePartUpLastDate == '1' && !getDisabled(scope.row.recNo)">/</span>
										<span
											v-if="scope.row.unusePartUpLastDate == '0' && !getDisabled(scope.row.recNo)">{{
												scope.row.partUpLastDate }}</span>
										<el-date-picker v-model="scope.row.partUpLastDate" type="date"
											value-format="yyyy-MM-dd" placeholder="选择日期"
											:disabled="scope.row.unusePartUpLastDate == '1'"
											v-if="getDisabled(scope.row.recNo)" style="width: 135px;">
										</el-date-picker>
										<el-switch v-model="scope.row.unusePartUpLastDate"
											v-if="getDisabled(scope.row.recNo)" active-value="1" inactive-value="0"
											style="margin-left: -7px;" active-color="#cdcdce"
											inactive-color="#7dbae2"></el-switch>
									</template>
								</el-table-column>
								<el-table-column prop="afterClosureWeldingDate" label="合拢焊后时间" width="200" align="center">
									<template slot-scope="scope">
										<span
											v-if="scope.row.unuseAfterClosureWeldingDate == '1' && !getDisabled(scope.row.recNo)">/</span>
										<span
											v-if="scope.row.unuseAfterClosureWeldingDate == '0' && !getDisabled(scope.row.recNo)">{{
												scope.row.afterClosureWeldingDate }}</span>
										<el-date-picker v-model="scope.row.afterClosureWeldingDate" type="date"
											value-format="yyyy-MM-dd" placeholder="选择日期"
											:disabled="scope.row.unuseAfterClosureWeldingDate == '1'"
											v-if="getDisabled(scope.row.recNo)" style="width: 135px;">
										</el-date-picker>
										<el-switch v-model="scope.row.unuseAfterClosureWeldingDate"
											v-if="getDisabled(scope.row.recNo)" active-value="1" inactive-value="0"
											style="margin-left: -7px;" active-color="#cdcdce"
											inactive-color="#7dbae2"></el-switch>
									</template>
								</el-table-column>
								<el-table-column prop="remark" label="备注" align="left" width="220">
									<template slot-scope="scope">
										<span style="margin-left: 15px;" v-if="!getDisabled(scope.row.recNo)">{{
											dataList[scope.$index].remark
										}}</span>
										<el-input class="sp-input" v-if="getDisabled(scope.row.recNo)"
											v-model="dataList[scope.$index].remark" style="width: 90%;"></el-input>
									</template>
								</el-table-column>
								<el-table-column label="操作" width="110" align="center" v-if="showDelete || showEdit"
									fixed="right">
									<template slot-scope="scope">
										<el-button v-preventReClick type="text" size="small"
											@click="updateHandle(scope.row.recNo)"
											v-if="showEdit && !updateFlg && !addFlg">修改</el-button>
										<el-button v-preventReClick type="text" size="small"
											@click="deleteHandle(scope.row.recNo)"
											v-if="showDelete && !updateFlg && !addFlg">删除</el-button>
										<!--新增时按钮-->
										<el-button v-preventReClick type="text" size="small" @click="saveShipProgress()"
											v-if="scope.row.recNo == undefined && addFlg">保存</el-button>
										<el-button v-preventReClick type="text" size="small" @click="cancelSaveHandle()"
											v-if="scope.row.recNo == undefined && addFlg">取消</el-button>
										<!--修改时按钮-->
										<el-button v-preventReClick type="text" size="small"
											@click="updateShipProgress(scope.row)"
											v-if="showEdit && getDisabled(scope.row.recNo) && !addFlg">保存</el-button>
										<el-button v-preventReClick type="text" size="small"
											@click="cancelUpdateHandle(scope.row)"
											v-if="showEdit && getDisabled(scope.row.recNo) && !addFlg">取消</el-button>
									</template>
								</el-table-column>
							</el-table>
						</div>
						<el-pagination class="my-pagination" @size-change="sizeChangeHandle"
							@current-change="currentChangeHandle" :current-page="pageIndex" :page-sizes="[10, 20, 50, 100]"
							:page-size="pageSize" :total="totalPage" layout="total, sizes, prev, pager, next, jumper"
							:disabled="updateFlg || addFlg">
						</el-pagination>
					</el-col>
				</el-row>
			</el-card>
		</div>
		<add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="dataList"></add-or-update>
		<excel-upload v-if="excelUploadVisible" ref="excelUpload" @refreshDataList="dataList"></excel-upload>
	</div>
</template>
<script>
import AddOrUpdate from './shipProgress-add-or-update'
import ExcelUpload from './shipProgress-excel-upload'
import $common from "@/utils/common.js"
export default {
	data() {
		return {
			partNo: '',
			showSearch: true,
			showAdd: true,
			showDelete: true,
			showEdit: true,
			disabledBatchDelete: true,
			pageSpan: 12,
			addOrUpdateVisible: false,
			excelUploadVisible: false,
			updateFlg: false,
			addFlg: false,
			form: {},
			//当前页码
			pageIndex: 1,
			//当前记录数
			pageSize: 20,
			// 显示序号
			pageIndex2: 1,
			//总页数
			totalPage: 0,
			workNo: $common.getItem("workNo"),
			subjectNo: '',
			dataList: [],
			multipleSelection: [],
			recNos: [],
			subjectList: []
		};
	},
	components: {
		AddOrUpdate,
		ExcelUpload
	},
	mounted() {
		// this.showSearch = $common.isAuth('shipProgress:search');
		// this.showAdd = $common.isAuth('shipProgress:add');
		// this.showDelete = $common.isAuth('shipProgress:delete');
		// this.showEdit = $common.isAuth('shipProgress:edit');
		if (!this.showAdd) {
			this.pageSpan = 24;
		}
		// if (this.showSearch) {
		// 	this.getDataList();
		// }
		this.getSubjectDropDown();
		this.pageIndex2 = 1;
	},
	methods: {
		getSubjectDropDown() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getSubjectDropDown/" + $common.getItem("workNo"),
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.subjectList = data.body;
					if (this.subjectList != undefined && this.subjectList.length > 0) {
						this.subjectNo = this.subjectList[0].displayValue
						if (this.showSearch) {
							this.getDataList();
						}
					}
				}
			});
		},
		setSubject(subject) {
			if (!this.addFlg && !this.updateFlg) {
				this.subjectNo = subject.displayValue
				this.getDataList()
			}
		},
		addHandle() {
			// 首行新增一条记录
			let dataList = [];
			let shipprogress = {
				workNo: $common.getItem("workNo"),
				subjectName: '',
				subjectNo: this.subjectNo,
				partNo: '',
				partNo: '',
				unusePartUpDate: '0',
				partCheckFinishDate: '',
				unusePartCheckFinishDate: '0',
				partPaintingFinishDate: '',
				unusePartPaintingFinishDate: '0',
				totalPartFinishDate: '',
				unuseTotalPartFinishDate: '0',
				partUpLastDate: '',
				unusePartUpLastDate: '0',
				afterClosureWeldingDate: '',
				unuseAfterClosureWeldingDate: '0',
				remark: '',
				sort: ''
			}
			dataList.push(shipprogress);
			for (var i = 0; i < this.dataList.length; i++) {
				dataList.push(this.dataList[i])
			}
			this.dataList = dataList
			this.addFlg = true;
		},
		cancelSaveHandle() {
			this.addFlg = false;
			let dataList = [];
			for (var i = 0; i < this.dataList.length; i++) {
				if (this.dataList[i].recNo != undefined) {
					dataList.push(this.dataList[i])
				}
			}
			this.dataList = dataList
		},
		cancelUpdateHandle(val) {
			this.getDataList()
			this.recNos = [];
			this.updateFlg = false;
		},
		updateHandle(val) {
			this.recNos.push(val)
			this.updateFlg = true;
		},
		getDisabled(val) {
			if (val == '' || val == undefined) {
				return true;
			} else {
				if (this.recNos.includes(val) && this.updateFlg) {
					return true;
				}
				return false;
			}
		},
		uploadHandle() {
			this.excelUploadVisible = true;
			this.$nextTick(() => {
				this.$refs.excelUpload.init()
			})
		},
		downloadHandle() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/shipprogressrecord/download",
				method: 'get',
				params: this.$http.adornParams({
					// page: this.pageIndex,
					partNo: this.partNo,
					subjectNo: this.subjectNo,
					// limit: this.pageSize,
					workNo: $common.getItem("workNo"),
				})
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					if (this.$store.state.showTestFlag) {
						window.open(data.body)
					} else {
						this.global.downLoadFile(data.body, '船体结构进度表.xlsx');
					}
				} else {
				}
			})
		},
		handleSelectionChange(selection) {
			this.multipleSelection = selection;
			if (selection.length > 0) {
				this.disabledBatchDelete = false;
			} else {
				this.disabledBatchDelete = true;
			}
		},
		deleteBatch() {
			this.$confirm(`确定对选中的数据进行删除操作？`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let records = this.multipleSelection.map((item) => item.recNo);
				this.$http({
					url: this.$store.state.httpUrl + "/business/shipprogressrecord/deleteBatch",
					method: 'post',
					data: this.$http.adornData({
						records: records
					})
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					} else {
						this.$message.error(data.msg)
					}
				})

			});
		},
		downloadTemplate() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getTemplateUrl?type=6",
				method: 'get',
				params: {}
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					if (data.body) {
						// window.open(data.body);
						if (this.$store.state.showTestFlag) {
							window.open(data.body)
						} else {
							this.global.downLoadFile(data.body, '船体结构进度表模板.xlsx');
						}
					} else {
						this.$message.error("未配置模板，请联系运维管理人员")
					}
				}
			});
		},
		// 获取数据列表
		getDataList() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/shipprogressrecord/list",
				method: 'get',
				params: this.$http.adornParams({
					page: this.pageIndex,
					partNo: this.partNo,
					subjectNo: this.subjectNo,
					limit: this.pageSize,
					workNo: $common.getItem("workNo"),
				})
			}).then(({
				data
			}) => {
				if (data && data.resultCode === 200) {
					this.dataList = data.body.records;
					this.totalPage = data.body.totalCount;
					this.pageIndex2 = this.pageIndex
				} else {
					this.dataList = []
					this.totalPage = 0
					this.pageIndex2 = 1
				}
			})
		},
		siteContentViewHeight() {
			var height = this.$store.state.common.documentClientHeight - 260;
			return {
				height: height + 'px'
			}
		},
		addOrUpdateHandle(id) {
			this.addOrUpdateVisible = true;
			this.$nextTick(() => {
				this.$refs.addOrUpdate.init(id)
			})
		},
		saveShipProgress() {
			if (this.dataList[0].subjectNo == null || this.dataList[0].subjectNo == "") {
				this.$message.error("请选择标的物");
				return;
			}

			if (this.dataList[0].partNo == null || this.dataList[0].partNo == "") {
				this.$message.error("请填写分段号");
				return;
			}

			let _url = "/business/shipprogressrecord/getMaxSort";
			this.$http({
				url: this.$store.state.httpUrl + _url,
				method: "post",
				data: this.$http.adornData({
					workNo: this.dataList[0].workNo,
					subjectNo: this.dataList[0].subjectNo,
				})
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.dataList[0].sort = data.body
					_url = "/business/shipprogressrecord/save";
					this.$http({
						url: this.$store.state.httpUrl + _url,
						method: "post",
						data: this.$http.adornData(this.dataList[0])
					}).then(({
						data
					}) => {
						if (data && data.resultCode === 200) {
							this.$message({
								message: "操作成功",
								type: "success",
								duration: 1500,
							});
							this.getDataList()
							this.addFlg = false
						}
					});
				}
			});
		},
		updateShipProgress(val) {
			if (val.partNo == null || val.partNo == "") {
				this.$message.error("请填写分段号");
				return;
			}

			let _url = "/business/shipprogressrecord/update";
			this.$http({
				url: this.$store.state.httpUrl + _url,
				method: "post",
				data: this.$http.adornData(val)
			}).then(({
				data
			}) => {
				if (data && data.resultCode === 200) {
					this.$message({
						message: "操作成功",
						type: "success",
						duration: 1500,
					});
					this.recNos = []
					this.getDataList()
					this.updateFlg = false
				}
			});
		},
		deleteHandle(id) {
			this.$confirm(`确定对选中的数据进行删除操作?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/shipprogressrecord/delete/" + id,
					method: 'post'
				}).then(({
					data
				}) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					} else {
						this.$message.error(data.msg)
					}
				})
			});
		},
		resetFormData() {
			// this.subjectNo = ''
			this.partNo = "";
			this.pageIndex = 1;
			this.updateFlg = false
			this.recNos = []
			this.getDataList();
		},
		selGetDataList() {
			this.pageIndex = 1;
			this.recNos = []
			this.updateFlg = false
			this.getDataList();
		},
		// 每页数
		sizeChangeHandle(val) {
			this.pageSize = val;
			this.pageIndex = 1;
			this.getDataList();
		},
		// 当前页
		currentChangeHandle(val) {
			this.pageIndex = val;
			this.getDataList();
		},
		tableRowClassName({ row, rowIndex }) {
			return $common.tableRowClassName({ row, rowIndex });
		},
		selectRowBackground({ row }) {
			const checkIdList = this.multipleSelection.map((item) => item.recNo);
			if (checkIdList.includes(row.recNo)) {
				return {
					backgroundColor: "#fae8cb",
				};
			}
		},
	}
};
</script>
<style scoped>
.selItemText {
	font-size: 14px;
	color: #1c1c1c;
	display: inline-block;
	width: 80px;
}

.sp-input /deep/ .el-input__inner {
	width: 100% !important;
}

.my-pagination {
	margin-right: 20px !important;
}

::v-deep(.el-table th.el-table__cell) {
	/* background-color: #d3e2f4 !important; */
	font-size: 15px;
	padding: 2px 0;
}

::v-deep(.selSubjectInput .el-input__inner) {
	width: 120px !important;
	height: 30px !important;
}

::v-deep(.selSearchInput .el-input__inner) {
	width: 150px !important;
	height: 30px !important;
}

::v-deep(.selFormItem .el-form-item__label) {
	font-size: smaller;
}
</style>
