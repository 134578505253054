<template>
	<el-dialog :title="!dataForm.recNo ? '新增结构进度' : '修改结构进度'" :close-on-click-modal="false" :visible.sync="visible"
		width="1150px">
		<el-form ref="dataForm" :model="dataForm" :rules="dataRule" inline label-width="120px" label-position="left">
			<el-row>
				<el-col :span="12">
					<el-form-item label="标的物" prop="subjectNo">
						<el-select class="selItemInput" v-model="dataForm.subjectNo" placeholder="请选择" prop="subjectNo"
							@change="handleChange" :disabled="updateSortFlg">
							<el-option v-for="item in $store.state.common.navBarSubjectList" :key="item.displayValue"
								:label="item.displayName" :value="item.displayValue">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="序号" prop="sort">
						<el-input-number v-model="dataForm.sort" controls-position="right" :min="1"
							disabled="true"></el-input-number>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="分段号" prop="partNo">
						<el-input class="selItemInput" type="input" v-model="dataForm.partNo" placeholder="请填写">
						</el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="上胎时间">
						<el-date-picker class="selItemInput" v-model="dataForm.partUpDate" type="date"
							value-format="yyyy-MM-dd" placeholder="选择日期" :disabled="unusePartUpDateFlg">
						</el-date-picker>
						<el-label style="padding:0 10px;">使用</el-label>
						<el-switch v-model="unusePartUpDateFlg" active-color="#cdcdce" inactive-color="#7dbae2"></el-switch>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="验收完成时间">
						<el-date-picker class="selItemInput" v-model="dataForm.partCheckFinishDate" type="date"
							value-format="yyyy-MM-dd" placeholder="选择日期" :disabled="unusePartCheckFinishDateFlg">
						</el-date-picker>
						<el-label style="padding:0 10px;">使用</el-label>
						<el-switch v-model="unusePartCheckFinishDateFlg" active-color="#cdcdce" inactive-color="#7dbae2"></el-switch>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="涂装完成时间">
						<el-date-picker class="selItemInput" v-model="dataForm.partPaintingFinishDate" type="date"
							value-format="yyyy-MM-dd" placeholder="选择日期" :disabled="unusePartPaintingFinishDateFlg">
						</el-date-picker>
						<el-label style="padding:0 10px;">使用</el-label>
						<el-switch v-model="unusePartPaintingFinishDateFlg" active-color="#cdcdce" inactive-color="#7dbae2"></el-switch>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="总段验收完成">
						<el-date-picker class="selItemInput" v-model="dataForm.totalPartFinishDate" type="date"
							value-format="yyyy-MM-dd" placeholder="选择日期" :disabled="unuseTotalPartFinishDateFlg">
						</el-date-picker>
						<el-label style="padding:0 10px;">使用</el-label>
						<el-switch v-model="unuseTotalPartFinishDateFlg" active-color="#cdcdce" inactive-color="#7dbae2"></el-switch>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="上船台(总组)时间">
						<el-date-picker class="selItemInput" v-model="dataForm.partUpLastDate" type="date"
							value-format="yyyy-MM-dd" placeholder="选择日期" :disabled="unusePartUpLastDateFlg">
						</el-date-picker>
						<el-label style="padding:0 10px;">使用</el-label>
						<el-switch v-model="unusePartUpLastDateFlg" active-color="#cdcdce" inactive-color="#7dbae2"></el-switch>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="备注">
						<el-input type="textarea" style="width: 918px" v-model="dataForm.remark" :rows="3"
							placeholder="请输入内容" />
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="formBtnGroup">
				<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain
					@click="visible = false">取消</el-button>
				<el-button v-preventReClick class="primaryPlainBtn" type="primary" @click="dataFormSubmit()">确定</el-button>
			</el-row>
		</el-form>
	</el-dialog>
</template>

<script>
import $common from "@/utils/common.js"
export default {
	name: "information-add-or-update",
	data() {
		return {
			visible: true,
			unusePartUpDateFlg: false,
			unusePartCheckFinishDateFlg: false,
			unusePartPaintingFinishDateFlg: false,
			unuseTotalPartFinishDateFlg: false,
			unusePartUpLastDateFlg: false,
			updateSortFlg: false,
			form: {},
			dataForm: {
				workNo: $common.getItem("workNo"),
				subjectNo: '',
				recNo: '',
				partNo: '',
				partUpDate: "",
				partCheckFinishDate: '',
				partPaintingFinishDate: '',
				totalPartFinishDate: '',
				partUpLastDate: '',
				sort: '1',
				remark: ''
			},
			dataRule: {
				subjectNo: [
					{ required: true, message: "标的物不能为空", trigger: "blur" }
				],
				sort: [
					{ required: true, message: "序号不能为空", trigger: "blur" }
				],
				partNo: [
					{ required: true, message: "分段号不能为空", trigger: "blur" }
				]
			},
		};
	},
	components: {

	},
	// mounted() {
	// 	this.getSubjectDropDown();
	// },
	methods: {
		getCurrentTime() {
			//获取当前时间并打印
			var _this = this;
			let yy = new Date().getFullYear();
			let mm = new Date().getMonth() + 1;
			let dd = new Date().getDate();
			let hh = new Date().getHours();
			let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
			let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
			return yy + '-' + mm + '-' + dd;
		},
		init(id) {
			this.dataForm = {
				workNo: $common.getItem("workNo"),
				subjectNo: '',
				recNo: '',
				partNo: '',
				partUpDate: "",
				partCheckFinishDate: '',
				partPaintingFinishDate: '',
				totalPartFinishDate: '',
				partUpLastDate: '',
				sort: '1'
			}
			this.dataForm.recNo = id ? id : 0;
			if (id) {
				this.getDetail();
			}
			this.$nextTick(() => {
				this.$refs['dataForm'].resetFields();
				this.visible = true;
			})
			if (id) {
				this.updateSortFlg = true
			} else {
				this.updateSortFlg = false
			}
		},
		handleChange() {
			if (this.dataForm.subjectNo) {
				this.getMaxSort();
			}
		},
		getMaxSort() {
			let _url = "/business/shipprogressrecord/getMaxSort";
			this.$http({
				url: this.$store.state.httpUrl + _url,
				method: "post",
				data: this.$http.adornData({
					workNo: this.dataForm.workNo,
					subjectNo: this.dataForm.subjectNo,
				})
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.$set(this.dataForm, 'sort', data.body)
				}
			});
		},
		getDetail() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/shipprogressrecord/info/" + this.dataForm.recNo,
				method: "get",
				params: {},
			}).then(({
				data
			}) => {
				if (data && data.resultCode === 200) {
					this.dataForm = data.body;
					if (this.dataForm.unusePartUpDate == '1') {
						this.unusePartUpDateFlg = true
					} else {
						this.unusePartUpDateFlg = false
					}
					if (this.dataForm.unusePartCheckFinishDate == '1') {
						this.unusePartCheckFinishDateFlg = true
					} else {
						this.unusePartCheckFinishDateFlg = false
					}
					if (this.dataForm.unusePartPaintingFinishDate == '1') {
						this.unusePartPaintingFinishDateFlg = true
					} else {
						this.unusePartPaintingFinishDateFlg = false
					}
					if (this.dataForm.unuseTotalPartFinishDate == '1') {
						this.unuseTotalPartFinishDateFlg = true
					} else {
						this.unuseTotalPartFinishDateFlg = false
					}
					if (this.dataForm.unusePartUpLastDate == '1') {
						this.unusePartUpLastDateFlg = true
					} else {
						this.unusePartUpLastDateFlg = false
					}
				}
			});
		},
		dataFormSubmit() {
			this.$refs["dataForm"].validate(valid => {
				if (valid) {
					let _url = "/business/shipprogressrecord/save";
					if (this.dataForm.recNo && this.dataForm.recNo != 0) {
						_url = "/business/shipprogressrecord/update";
					}
					if (this.unusePartUpDateFlg) {
						this.dataForm.unusePartUpDate = '1'
					} else {
						this.dataForm.unusePartUpDate = '0'
					}
					if (this.unusePartCheckFinishDateFlg) {
						this.dataForm.unusePartCheckFinishDate = '1'
					} else {
						this.dataForm.unusePartCheckFinishDate = '0'
					}
					if (this.unusePartPaintingFinishDateFlg) {
						this.dataForm.unusePartPaintingFinishDate = '1'
					} else {
						this.dataForm.unusePartPaintingFinishDate = '0'
					}
					if (this.unuseTotalPartFinishDateFlg) {
						this.dataForm.unuseTotalPartFinishDate = '1'
					} else {
						this.dataForm.unuseTotalPartFinishDate = '0'
					}
					if (this.unusePartUpLastDateFlg) {
						this.dataForm.unusePartUpLastDate = '1'
					} else {
						this.dataForm.unusePartUpLastDate = '0'
					}
					this.$http({
						url: this.$store.state.httpUrl + _url,
						method: "post",
						data: this.$http.adornData(this.dataForm)
					}).then(({
						data
					}) => {
						if (data && data.resultCode === 200) {
							this.$message({
								message: "操作成功",
								type: "success",
								duration: 1500,
								onClose: () => {
									this.visible = false;
									this.$parent.getDataList();
								}
							});
						} else {
							this.$message.error(data.msg);
						}
					});
				}
			});
		},

	}
};
</script>

<style lang="scss" scoped="scoped">
.selItemInput.descAreaLg {
	width: 718px !important;
}

.addImgBox {
	width: 136px;
	height: 136px;
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
}

.fondstyle {
	color: #3f649f;
	font-size: 15px;
}
</style>
